














.ds-home{
  display: flex;
  // width: 100%;
  min-height: 1000px;
}
